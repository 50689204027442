.moonEngineNavBar {
  opacity: 0.8;
  background-color: #028090;
  width: 100vw;
}

#moonEngineHeading {
  color: #FFFFFF;
  font-size: 3vh;
  font-family: 'Arial', sans-serif;
  padding: 0px;
  margin: 0px;
}

.homeLinkMoonEngine {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin-right: 20px;
}

.moonInfoUnit {
  margin: 20px 0px 20px 0px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  color: #FFFFFF;
  width: 86%;
  border-radius: 8px;
  background:linear-gradient(135deg, #3d7b8a 0%,#1c4652 100%);
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.moonInfoUnit:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.moonInfoContainer{
  width: 100%;
  height: 100%;
  overflow: auto;
}

.moonInfoSection {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 20px 0px 20px 0px;
}

.moonEngineFooterDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.moonEngineFooter {
  background:linear-gradient(135deg, #3d7b8a 0%,#1c4652 100%);
  color: #FFFFFF;
  text-align: center;
  border-radius: 8px;
  width: 40vw;
  height: 10vh;
  margin: 20px 0px 20px 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  font-size: 2vh;
}

.moonEngineFooter:focus {
  outline: none;
}

.moonEngineFooter:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.moonEngineText {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  color: #FFFFFF;
  text-align: center;
  margin: 0px 0px 10px 0px;
}

.moonPicEngineStyle {
  height: 80px;
  border-radius: 40px;
  margin: 0px 0px 10px 0px;
}

.moonEngineSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: 'Arial', sans-serif;
}

@media only screen and (orientation: landscape) {
  #moonEngineHeading {
    font-size: 5vh;
  }
  .moonEngineText {
    font-size: 2.4vh;
  }
}

@media only screen and (min-width: 1020px) and (orientation: landscape) {
  #moonEngineHeading {
    font-size: 4.2vh;
  }
}

@media only screen and (min-width: 764px) and (orientation: portrait) {
  .moonInfoContainer {
    width: 50%;
    float: left;
  }

  .moonInfoUnit {
    width: 92%;
    height: 38vh;
  }
}

@media only screen and (min-width: 1020px) and (orientation: landscape) {
  .moonInfoContainer {
    width: 50%;
    float: left;
  }

  .moonInfoUnit {
    width: 92%;
    height: 50vh;
  }
}
