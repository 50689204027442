
.favFont {
  font-family: 'Arial', sans-serif;
  color: #FFFFFF;
}

p {
  font-family: 'Arial', sans-serif;
}


.main {
  border: 2px solid white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  background-color: #028090;
  color: white;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 1.7em;
}

.main-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.9;
}

.favItem {
  border: 2px solid white;
  border-radius: 4px;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #028090;
  color: white;
}

.backButton{
  background-color: hsla(360, 100%, 100%, 0.1);
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.backButton:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.9;
}

.iconStyle{
  font-size: 2em;
}

.favContainerMap {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
