
/* Applicable to all mobile phones. */
.background {
  background: rgb(36,47,62);
  background: linear-gradient(0deg, rgba(36,47,62,1) 22%, rgba(23,38,48,1) 55%);
  margin: 0px;
  height: 140vh;
  padding: 0px;
}

.formSignup {
  width: 100vw;
  height: 140vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signUpText {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin: 1vh 0px 1vh 0px;
  font-size: 5vh;
}

.signUpInstructionEnveloppe {
  background-color: #E6AA68;
  border-radius: 1vh;
  padding: 1vh;
  margin: 1vh 2vw 1vh 2vw;
  width: 80vw;
}

.signupInstruction {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
  margin: 0px;
}

.signupFields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

.userNameSignupForm {
  margin: 0px;
}

.usernameInput {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
}

.usernameInputError {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
  box-shadow: 0px 0px 2px 1px #FF0000;
}

.usernameErrorPromptStyle {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  text-align: justify;
  margin: 1vh 2vw 1vh 2vw;
}

.emailSignupForm {
  margin: 0px;
}

.emailSignupInput {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
}

.emailSignupInputError {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
  box-shadow: 0px 0px 2px 1px #FF0000;
}

.emailErrorPromptStyle {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  text-align: justify;
  margin: 1vh 2vw 1vh 2vw;
}

.passwordSignupForm {
  margin: 0px;
}

.passwordSignupInput {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
}

.passwordSignupInputError {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
  box-shadow: 0px 0px 2px 1px #FF0000;
}

.passwordErrorPromptStyle {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  text-align: justify;
  margin: 1vh 2vw 1vh 2vw;
}

.signupButtonForm {
  margin: 0px;
}

.cancelButton {
  background-color: hsla(0, 100%, 0%, .4);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  border-radius: 10px;
  margin: 4vh 10vw 4vh 0px;
  transition: opacity 0.3s;
}

.cancelButton:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.9;
}

.submit {
  background-color: hsla(0, 100%, 0%, .4);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  border-radius: 10px;
  margin: 4vh 0px 4vh 0px;
  transition: opacity 0.3s;
}

.submit:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.9;
}

.connectionBadge {
  background-color: hsla(0, 100%, 1%, .7);
  border-color: #FFFFFF;
  color: #FFFFFF;
  padding:10px;
  font-family: 'Arial', sans-serif;
  border-radius: 10px;
  margin: 4vh 0px 4vh 0px;
  transition: opacity 0.3s;
}

.connectionBadge:hover {
  background-color: black;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.4;
}

/* All mobile phones in landscape format. */
@media only screen and (orientation: landscape) {
  .background {
    height: 200vh;
  }

  .formSignup {
    width: 100vw;
    height: 200vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signUpText {
    font-size: 8vh;
  }
}

/* All tablets in portrait orientation or bigger devices. */
@media only screen and (min-width: 764px) and (orientation: portrait) {
  .background {
    height: 100vh;
  }

  .formSignup {
    width: 100vw;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signUpInstructionEnveloppe {
    width: 70vw;
  }

  .usernameInput {
    width: 60vw;
  }

  .usernameInputError {
    width: 60vw;
  }

  .emailSignupInput {
    width: 60vw;
  }

  .emailSignupInputError {
    width: 60vw;
  }

  .passwordSignupInput {
    width: 60vw;
  }

  .passwordSignupInputError {
    width: 60vw;
  }

}

/* All tablets in landscape format, desktops and laptops. */
@media only screen and (min-width: 1020px) and (orientation: landscape) {
  .background {
    height: 110vh;
  }

  .formSignup {
    width: 100vw;
    height: 110vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signUpText {
    font-size: 5vh;
  }

  .signUpInstructionEnveloppe {
    width: 60vw;
  }

  .usernameInput {
    width: 60vw;
  }

  .usernameInputError {
    width: 60vw;
  }

  .emailSignupInput {
    width: 60vw;
  }

  .emailSignupInputError {
    width: 60vw;
  }

  .passwordSignupInput {
    width: 60vw;
  }

  .passwordSignupInputError {
    width: 60vw;
  }

}

/* Styles for all devices */


/* all mobile phones in portrait orientation and bigger devices */
/* @media only screen and (min-width: 319px) and (orientation: portrait) {
  .signupFields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .signupFieldsError{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .usernameInput {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .emailSignupInput {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .passwordSignupInput {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cancelButton {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .submit {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .connectionBadge {
    margin-top: 20px;
  }

} */

/* all tablets and bigger devices */
/* @media only screen and (min-width: 767px) and (min-height: 600px) {
  .signUpText {
    margin-bottom: 20px;
  }
  .signupFields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .signupFieldsError{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .usernameInput {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .emailSignupInput {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .passwordSignupInput {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .cancelButton {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .submit {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .connectionBadge {
    margin-top: 15px;
  }

} */
