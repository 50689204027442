.navBarStyle {
  opacity: 0.8;
  background-color: #028090;
}

#navBarBrandStyle{
  color: white;
  font-family: 'Arial', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.locateMe {
  color: #FFFFFF;
  transition: transform 0.3s;
}

.locateMe:hover {
  transform: translateY(-1px);
}


.signInLink{
  margin-right: 20px;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
}

.signUpLink {
  margin-right: 20px;
  font-family: 'Arial', sans-serif;
  color: #FFFFFF;
}

.favorisLink {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin: 0px 20px 0px 0px;
  padding: 0px;
}

.favorisLink:hover,
.favorisLink:focus {
  color: #59D2FE;
  border: none;
  outline: none;
}

.homeLink{
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin-right: 20px;
}

.toggleStyle{
  margin-right: 20px;
}

/* all devices with width greater than 760 px. This creates space between */
/* block of logo and Dark Sky Map title and geolocation pin. */
@media only screen and (min-width: 760px) {
  .locateMe {
    margin-left: 20px;
  }
}
