.atlasLegendParent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 72vh;
  width: 36vw;
  background-color: #17263C;
  border-radius: 2.8vh;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: auto;
}

.topRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36vw;
  height: 8vh;
}

.legendHeading {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
}

.legendClose {
  color: #FFFFFF;
  margin: 0px 0px 0px 0px;
  position: absolute;
  top: 3vh;
  right: 2vw;
}

.legendContent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 64vh;
}

.colourColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 64vh;
  width: 18vw;
}

.bortleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 64vh;
  width: 18vw;
}

.bortleText {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin: 0px 0px 0px 0px;
}

.placeBlack {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #000000;
}

.placeDarkBlue {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #000467;
}

.placeLightBlue {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #4040FF;
}

.placeGreen {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #989623;
}

.placeYellow {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #FEFE40;
}

.placeOrange {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #F9AF0B;
}

.placeRed {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #FF1E00;
}

.placePink {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #E991CF;
}

.placeWhite {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #FFFFFF;
}

.placeDogwoodRose {
  height: 2vh;
  width: 2vh;
  border-radius: 1vh;
  background-color: #D90368;
}

/* All mobile phones in portrait orientation */
@media only screen and (min-width: 318px) and (orientation: portrait) {
  .atlasLegendParent {
    height: 84vh;
    width: 84vw;
  }
  .topRow {
    width: 84vw;
    height: 8vh;
  }
  .legendContent {
    height: 76vh;
  }

  .colourColumn {
    height: 76vh;
    width: 42vw;
  }

  .bortleColumn {
    height: 76vh;
    width: 42vw;
  }

  .legendClose {
    top: 2.6vh;
    right: 3vw;
  }

}

/* All mobile phones in landscape orientation */
@media only screen and (min-width: 318px) and (orientation: landscape) {
  .atlasLegendParent {
    height: 84vh;
    width: 66vw;
  }
  .topRow {
    width: 66vw;
    height: 8vh;
  }
  .legendContent {
    height: 76vh;
  }

  .colourColumn {
    height: 76vh;
    width: 33vw;
  }

  .bortleColumn {
    height: 76vh;
    width: 33vw;
  }

  .legendClose {
    top: 2.6vh;
    right: 3vw;
  }
}

/* For tablets in portrait orientation */
@media only screen and (min-width: 764px) and (orientation: portrait) {
  .atlasLegendParent {
    height: 72vh;
    width: 60vw;
  }
  .topRow {
    width: 60vw;
    height: 8vh;
  }
  .legendContent {
    height: 64vh;
  }

  .colourColumn {
    height: 64vh;
    width: 30vw;
  }

  .bortleColumn {
    height: 64vh;
    width: 30vw;
  }

  .legendClose {
    top: 3vh;
    right: 3vw;
  }
}

/* For 13 inch laptops */
@media only screen and (min-width: 1270px) and (orientation: landscape) {
  .atlasLegendParent {
    height: 72vh;
    width: 48vw;
  }
  .topRow {
    width: 48vw;
    height: 8vh;
  }
  .legendContent {
    height: 64vh;
  }

  .colourColumn {
    height: 64vh;
    width: 24vw;
  }


  .bortleColumn {
    height: 64vh;
    width: 24vw;
  }

  .legendClose {
    top: 3vh;
    right: 3vw;
  }
}
