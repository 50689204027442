.landingPageContainer {
  background: rgb(36,47,62);
  background: linear-gradient(0deg, rgba(36,47,62,1) 22%, rgba(23,38,48,1) 55%);
  height: 90vh;
}

.homeTopRowConnected{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  top: 4%;
}

.homeTopRow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 4%;
}

.landingButtonsDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.btn-Home-Sign1{
  background-color: hsla(360, 100%, 100%, 0.1);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-size: 3vh;
  margin: 0px 10px 0px 0px;
}

.btn-Home-Sign1:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.7;
}

.btn-Home-Sign2{
  background-color: hsla(360, 100%, 100%, 0.1);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-size: 3vh;
  margin: 0px 0px 0px 10px;
}

.btn-Home-Sign2:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.7;
}

.homeContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
}

.homeTitle{
  color: #FFFFFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin: 0px 0px 3vh 0px;
  font-size: 6vh;
}

.homeSubTitle{
  color: #FFFFFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin: 1vh 0px 1vh 0px;
  font-size: 4vh;
}

.homeText{
  color: #FFFFFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
  margin: 3vh 0px 0px 0px;
  font-size: 4vh;
}

.btnHomeDiscover{
  background-color: hsla(360, 100%, 100%, 0.1);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-size: 3vh;
  margin: 4vh 0px 0px 0px;
}

.btnHomeDiscover:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.7;
}

.msgButton {
  background-color: hsla(360, 100%, 100%, 0.1);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-size: 2.7vh;
  margin: 4vh 0px 0px 0px;
}

.msgButton:hover {
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.7;
}

.appStoreSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 8vh 0px 8vh 0px;
}

.introVideo {
  margin: 0px 0px 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.presentationVideo {
  width: 80vw;
  height: calc(80vw * 0.5625);
  border-radius: 6px;
}

.homeButtonSection {
  margin: 0px 0px 10px 0px;
  height: 100%;
  overflow: auto;
}


.homeButtonContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
}

.componentBridge{
  width: 56%;
  height: 60px;
  margin: 10px 0px 10px 0px;
  font-family: 'Arial', sans-serif;
  border-radius: 8px;
  font-size: 14px;
  color: #FFFFFF;
  background: linear-gradient(to right, #4B79A1 0%, #283E51 100%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.componentBridge:focus{
  outline: none;
}

.componentBridge:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.featureSection {
  margin: 10px 0px 0px 0px;
  height: 100%;
  overflow: auto;
}

.firstfeatureContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.secondfeatureContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.mapFeature{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 0px 0px;
  width: 100vw;
  height: 430px;
}

.mapFeatureIcon {
  color: #247BA0;
  font-size: 55px;
  opacity: 0.6;
  margin: 0px 0px 20px 0px;
}

.mapFeatureHeading {
  margin: 0px 0px 20px 0px;
  color: #40434E;
  font-family: 'Arial', sans-serif;
}

.cartographyList {
  color: #757780;
  font-family: 'Arial', sans-serif;
  margin: 0px;
}

.weatherFeature{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 0px 0px;
  width: 100vw;
  height: 215px;
}

.meteoIcon {
  color: #247BA0;
  font-size: 55px;
  opacity: 0.6;
  margin: 0px 0px 20px 0px;
}

.meteoFeatureHeading {
  margin: 0px 0px 20px 0px;
  color: #40434E;
  font-family: 'Arial', sans-serif;
}

.meteoList {
  color: #757780;
  font-family: 'Arial', sans-serif;
  margin: 0px;
}

.riceFeature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 0px 0px;
  width: 100vw;
  height: 215px;
}

.riceFeatureIcon {
  color: #247BA0;
  font-size: 55px;
  opacity: 0.6;
  margin: 0px 0px 20px 0px;
}

.riceFeatureHeading {
  margin: 0px 0px 20px 0px;
  color: #40434E;
  font-family: 'Arial', sans-serif;
}

.riceFeatureList {
  color: #757780;
  font-family: 'Arial', sans-serif;
  margin: 0px;
}

.transportFeature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 0px 0px;
  width: 100vw;
  height: 215px;
}

.transportFeatureIcon {
  color: #247BA0;
  font-size: 55px;
  opacity: 0.6;
  margin: 0px 0px 20px 0px;
}

.transportFeatureHeading {
  margin: 0px 0px 20px 0px;
  color: #40434E;
  font-family: 'Arial', sans-serif;
}

.transportFeatureList {
  color: #757780;
  font-family: 'Arial', sans-serif;
  margin: 0px;
}

.lunarFeature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 0px 0px;
  width: 100vw;
  height: 215px;
}

.lunarFeatureIcon {
  color: #247BA0;
  font-size: 55px;
  opacity: 0.6;
  margin: 0px 0px 20px 0px;
}

.lunarFeatureHeading {
  margin: 0px 0px 20px 0px;
  color: #40434E;
  font-family: 'Arial', sans-serif;
}

.lunarFeatureList {
  color: #757780;
  font-family: 'Arial', sans-serif;
  margin: 0px;
}

.bottomRow{
  background-color: #2A303B;
  margin: 20px 0px 0px 0px;
  padding: 45px 25px;
  color: #FFFFFF;
}

.bottomHeading{
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
}

.missionDescriptionStyle{
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
}

.spanEmail{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pEmail{
  margin: 0px 0px 0px 20px;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
}

.bottomAbout{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.bottomContact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.subsidiaries {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.subsidiaryButton {
  color: #ffffff;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  margin: 8vh 0px 0px 0px;
  background-color: #263242;
  border-color: transparent;
}

.socialMedia {
  margin: 8vh 0px 0px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
}

.handle {
  height: 10vh;
  margin: 0px 0px 0px 0px;
}

.partnerSection{
  margin: 8vh 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.safLogo {
  height: 26vh;
  width: calc(26vh);
}

.partnerSectionHeading{
  color: #40434E;
  font-family: 'Arial', sans-serif;
  margin: 0px 0px 2vh 0px;
}

.partnerLinksDiv{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw
}

/* For tablets in portrait orientation */
@media only screen and (min-width: 764px) and (orientation: portrait) {

  .firstfeatureContainer {
    width: 50%;
    float: left;
  }

  .mapFeature{
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .weatherFeature{
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .secondfeatureContainer {
    width: 33.33%;
    float: left;
  }

  .riceFeature {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .transportFeature {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .lunarFeature {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .homeTopRowConnected{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    top: 4%;
  }

  .homeTopRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 2%;
  }

  .landingButtonsDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    display: inline-block;
  }

}

/* For iPad in landscape orientation */
@media only screen and (min-width: 1020px) and (orientation: landscape) {

  .firstfeatureContainer {
    width: 50%;
    float: left;
  }

  .mapFeature{
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .weatherFeature{
    width: 100%;
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .secondfeatureContainer {
    width: 33.33%;
    float: left;
  }

  .riceFeature {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .transportFeature {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .lunarFeature {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .homeTopRowConnected{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    top: 4%;
  }

  .homeTopRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 1%;
  }

  .landingButtonsDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
    display: inline-block;
  }

}
