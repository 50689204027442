

.buttonStyleSelecW {
  color: #FFFFFF;
  border-color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin: 14px;
  font-size: 2vh;

}

.buttonStyleSelecW:hover {
  color: #028090;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}

.buttonDescStyleUnsW{
  color: #028090;
  background-color: #FFFFFF;
  border-color: #028090;
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  margin-left: 50px;
}

.buttonDescStyleUnsW:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
  background-color: transparent;
}

.modal-content{
  background-color:#028090;
  width: 75vw;
  font-family: 'Arial', sans-serif;
  position:absolute;
  position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, -0%) !important;

}


.buttonStyleBack{
  color: #FFFFFF;
  border-color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  padding: 5px;
}

.buttonStyleBack:hover{
  font-family: 'Arial', sans-serif;
  color: #028090;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}

.weatherdescforecast {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  color: #FFFFFF;
  margin: 5px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Arial', sans-serif;

}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.halfDayForecast{
  width: 50%;
  height: 250px;
  border: solid;
  border-color: #FFFFFF;
  border-width: thin;
  margin:10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(2, 128, 144, 0.5);
  transition: box-shadow 0.3s;
  transition: transform 0.3s;
  background-color:#639FAB;
  font-family: 'Arial', sans-serif;

}

.halfDayForecast:hover{
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);;
  transform: translateY(-3px);
}

.dateForecast {
  font-weight: bold;
  color: #FFFFFF;
  font-size: 2vh;
  margin-top: 10px;
  font-family: 'Arial', sans-serif;
}

.dayForecast{
  width: 100%;
  height: 250;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20;
  font-family: 'Arial', sans-serif;
}

/* smartphones orientation: portrait*/
@media only screen and (max-width: 414px) and (orientation: portrait) {
  .dayForecast {
    flex-direction: column;
  }
  .halfDayForecast{
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
  }
  .modal-header{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content{
     width: 100%;
  }
  .weatherdescforecast {
    font-size: 2vh;
  }
  .dateForecast{
    font-size: 2vh;
  }
  .buttonDescStyleUnsW{
    margin: 0px;

  }
  .buttonStyleSelecW{
    margin: 4px;
  }
}

/* smartphones orientation: landscape*/
@media only screen and (min-width: 563px) and (max-width: 812px) and (orientation: landscape) {
  .dayForecast {
    flex-direction: column;
  }
  .halfDayForecast{
    width: 50%;
    height: 50%;
    padding-bottom: 10px;
  }
  .modal-header{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content{
     width: 100%;
  }
  .weatherdescforecast {
    font-size: 3vh;
  }
  .dateForecast{
    font-size: 3vh;
  }
  .buttonStyleSelecW{
    font-size: 3vh;
  }
  .buttonDescStyleUnsW{
    font-size: 3vh;
  }
}

/* tablets orientation: portrait*/
@media only screen and (min-width: 766px) and (orientation: portrait) {


  .halfDayForecast{
    width: 50%;
    height: 50%;
    padding-bottom: 10px;
  }
  .weatherdescforecast {
    font-size: 2vh;
  }
  .dateForecast{
    font-size: 2vh;
  }

}

/* tablets orientation: landscape*/
@media only screen and (min-width: 1020px) and (orientation: landscape) {


  .halfDayForecast{
    width: 50%;
    height: 50%;
    padding-bottom: 10px;
  }
  .weatherdescforecast {
    font-size: 2vh;
  }
  .dateForecast{
    font-size: 2vh;
  }

}
