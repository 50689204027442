
#wrapper {
  position: relative;
}

#overMap {
  position: absolute; z-index: 2;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.addlocation{
  margin-left: 20px;
}

.navBar {
  opacity: 0.5;
}

h4 {
  font-family: 'Arial', sans-serif;
}

.text {
  font-family: 'Arial', sans-serif;
}

p {
  font-family: 'Arial', sans-serif;
}


.btn-Home-Sign1{
  background-color: hsla(360, 100%, 100%, 0.1);
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.btn-Home-Sign1:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.7;
}


.alertButton{
  display: flex;
  justify-content: center;
}

.alertSignIn{
  display: flex;
  justify-content: center
}

.descriptionIconStyle{
  transition: transform .2s;
}

.descriptionIconStyle:hover{
  transform: scale(1.1);
}

.detailsIconStyle{
  transition: transform .2s;
}

.detailsIconStyle:hover{
  transform: scale(1.1);
}

.dateDetails{
  margin-left: 10px
}

.nasaGibsAcknowledgementStyle {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
}

.moonWidgetStyle {
  text-decoration: none;
  position: absolute;
  bottom: 30px;
}

.mainMapLegend {
  font-size: 2em;
  margin: 0px 0px 0px 0px;
  position: absolute;
  bottom: 50vh;
  left: 4vw;
  color: #FFFFFF;
}

.satelliteMarkerStyle {
  opacity: 0.4;
  color: #FF0000;
}

.iconFeedBack{
  color: #FFFFFF;
  position: absolute;
  font-size: 2em;
  left: 97vw;
  top: 50vh;
}

/*smartphones*/
@media only screen and (max-width: 320px) and (orientation: portrait) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 87vw;
    top: 50vh;
  }
}

/*smartphones*/
@media only screen and (min-width: 355px) and (max-width: 375px) and (orientation: portrait) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 88vw;
    top: 50vh;
  }
}

/*smartphones*/
@media only screen and (min-width: 410px) and (max-width: 414px) and (orientation: portrait) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 89vw;
    top: 50vh;
  }
}

/*tablets*/
@media only screen and (min-width: 768px) and (max-width: 1020px) and (orientation: portrait) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 94vw;
    top: 50vh;
  }
}
/*tablets*/
@media only screen and (min-width: 1024px) and (max-width: 1026px) and (orientation: portrait) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 96vw;
    top: 50vh;
  }
}

/*smartphones landscape*/
@media only screen and (min-width: 640px) and (max-width: 823px) and (orientation: landscape) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 94vw;
    top: 50vh;
  }
}

/*smartphones landscape*/
@media only screen and (max-width: 568px) and (orientation: landscape) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 92vw;
    top: 50vh;
  }
}

/*smartphones landscape*/
@media only screen and (min-width: 736px) and (max-width: 812px) and (orientation: landscape) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 94vw;
    top: 50vh;
  }
}

/*tablets landscape*/
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
  .iconFeedBack{
    color: #FFFFFF;
    position: absolute;
    font-size: 2em;
    left: 96vw;
    top: 50vh;
  }
}
