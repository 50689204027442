.popup{
  position: absolute;
  width: 60%;
  height: 80%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #028090;
  border-radius: 10px;
}

.body{
  /* position: absolute; */
  overflow-y: scroll;
  width: 100%;
  height: 90%;
}

.headingBBC {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10%;

}

.inputBlablacarCityFrom{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: 5px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.inputWidgetCityFrom{
  width: 50%;
  margin: 10px
}

.buttonSearch{
  color: #FFFFFF;
  border-color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  padding: 5px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.buttonSearch:hover{
  color: #028090;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}

.inputWidget{
  width: 50%;
  margin: 10px
}

.inputText{
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  color: #FFFFFF;
  width: 60%;
  margin-top: 15px;
  text-align: center;
}

.location{
  color: #FFFFFF;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  margin-top: 10px;
  text-align: center;
}

.inputBlablacar{
  display: flex;
  margin: 5px;
  /* margin-bottom: 0px; */
  justify-content: center;
  align-items: center;

}
.inputBlablacar2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: 5px;

}

.popupDesc{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
  text-align: center;
}
.spaceLatLon{
  color: #FFFFFF;
  font-weight: bold;
}
.popupLine1{
  margin: 5px;
  font-size: 2vh;
  width: 100%;
  color: #FFFFFF;
  font-style: italic;
}
.popupLine2{
  margin: 5px;
  font-size: 2vh;
  width: 100%;
  color: #FFFFFF;
  font-style: italic;
}

/* smartphones orientation: portrait*/
@media only screen and (max-width: 414px) and (orientation: portrait) {
  .popup{
    width: 90%;
    height: 60%;
    overflow-y: scroll;
  }
  .headingBBC{
    margin: 15px;
  }

  .location{
  margin-top: 10px;
  }

  .inputWidgetCityFrom{
    width: 110%;
  }

  .inputWidget{
    width: 110%
  }
  .inputText{
    font-family: 'Arial', sans-serif;
    font-size: 2vh;
    color: #FFFFFF;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }

  .ant-calendar-picker{
    width: 120px;

  }
  .ant-calendar-picker-input{
    padding: 5px;
  }

  .buttonSearch{
    margin-bottom: 10px;
  }
  .inputBlablacar{
    width: 90%;
    margin-left: 15px;
  }
  .inputBlablacar2{
    width: 100%;
  }

  .spaceLatLon{
    margin: 8px;
  }

}

/* smartphones orientation: landscape*/
@media only screen and (min-width: 563px) and (max-width: 825px) and (orientation: landscape) {
  .popup{
    width: 85%;
    height: 70%;
    overflow-y: scroll;
  }

  .headingBBC{
    margin: 10px;
  }

  .inputWidgetCityFrom{
    width: 100%;
  }
  .inputWidget{
    margin-left: 10px;
    width: 100%
  }
  .inputText{
    font-family: 'Arial', sans-serif;
    font-size: 3vh;
    color: #FFFFFF;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }
  .spaceLatLon{
    margin: 8px;
  }
  .popupLine1{
    font-size: 3vh;
  }
  .popupLine2{
    font-size: 3vh;
  }

}

/* tablets orientation: portrait*/
@media only screen and (min-width: 766px) and (orientation: portrait) {
  .popup{
    width: 85%;
    height: 70%;
    overflow-y: scroll;
  }

  .inputText{
    font-family: 'Arial', sans-serif;
    font-size: 2vh;
    color: #FFFFFF;
    width: 100%;
    margin-top: 15px;
    text-align: center;
  }

}
