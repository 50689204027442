.searchCityButton {
  background-color: #028090;
  border-color: #028090;
}

.searchCityButton:hover {
  background-color: #034748;
  border-color: #034748;
}

.searchCityTextArea {
  border-color: #028090;
}

.searchCityTextArea:focus {
  outline: none;
  box-shadow: none;
  border-color: #028090;
}

.searchCityDiv {
  width: 50vw;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Arial', sans-serif;
}


@media only screen and (max-width: 825px) {
  .searchCityDiv {
    width: 80vw;
  }
}
