/* Applicable to all mobile phones. */
.parentDiv {
  background: rgb(36,47,62);
  background: linear-gradient(0deg, rgba(36,47,62,1) 22%, rgba(23,38,48,1) 55%);
}

.background {
  background: rgb(36,47,62);
  background: linear-gradient(0deg, rgba(36,47,62,1) 22%, rgba(23,38,48,1) 55%);
  height: 140vh;
}

.formSignin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100vw;
  height: 140vh;
}

.signInTitle {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin: 0px;
  font-size: 5vh;
}

.signInInstructionEnveloppe {
  background-color: #E6AA68;
  border-radius: 1vh;
  padding: 1vh;
  width: 90vw;
  margin: 1vh 0px 1vh 0px;
}

.signInInstruction {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
  margin: 0px;
}

.emailFormGroup {
  margin: 0px;
}

.email {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 4vh 0px 2vh 0px;
}

.passwordFormGroup {
  margin: 0px;
}

.password {
  width: 80vw;
  font-family: 'Arial', sans-serif;
  margin: 2vh 0px 2vh 0px;
}

.signinButtonForm {
  margin: 0px;
  padding: 0px;
}

.cancelButton1 {
  background-color: hsla(0, 100%, 0%, .4);;
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  border-radius: 10px;
  margin: 4vh 10vw 4vh 0px;
}

.cancelButton1:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.9;
}

.submit1 {
  background-color: hsla(0, 100%, 0%, .4);
  border-color: #FFFFFF;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  border-radius: 10px;
  margin: 4vh 0px 4vh 0px;
}

.submit1:hover{
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.9;
}


.inscriptionBadge {
  background-color: hsla(0, 100%, 1%, .7);
  border-color: #FFFFFF;
  color: #FFFFFF;
  padding: 10px;
  margin: 5vh 0px 5vh 0px;
  font-family: 'Arial', sans-serif;
  border-radius: 10px;
}

.inscriptionBadge:hover{
  background-color: black;
  border-color: #FFFFFF;
  color: #FFFFFF;
  opacity: 0.87;
}

.forgotPassword {
  font-family: 'Arial', sans-serif;
  color: #FFFFFF;
  margin: 0px;
  padding: 0px;
}


/* All mobile phones in landscape format. */
@media only screen and (orientation: landscape) {
  .background {
    height: 200vh;
  }

  .formSignin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100vw;
    height: 200vh;
  }

  .signInTitle {
    margin: 5vh 0px 5vh 0px;
    font-size: 8vh;
  }

  .forgotPassword {
    margin: 5vh 0px 5vh 0px;
  }
}

/* All tablets in portrait orientation or bigger devices. */
@media only screen and (min-width: 764px) and (orientation: portrait) {

  .signInInstructionEnveloppe {
    width: 80vw;
  }

  .email {
    width: 60vw;
    margin-top: 8vh;
    margin-bottom: 4vh;
  }
  .password {
    width: 60vw;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .cancelButton1 {
    margin: 4vh 10vw 4vh 0px;
  }

  .submit1 {
    margin: 4vh 0px 4vh 0px;
  }

}

/* All tablets in landscape format, desktops and laptops. */
@media only screen and (min-width: 1020px) and (orientation: landscape) {
  .background {
    height: 100vh;
  }

  .formSignin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100vw;
    height: 100vh;
  }

  .signInTitle {
    margin: 2vh 0px 2vh 0px;
    font-size: 5vh;
  }

  .signInInstructionEnveloppe {
    width: 60vw;
  }

  .email {
    width: 60vw;
    margin-top: 8vh;
    margin-bottom: 4vh;
  }
  .password {
    width: 60vw;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }

  .cancelButton1 {
    margin: 4vh 10vw 4vh 0px;
  }

  .submit1 {
    margin: 4vh 0px 4vh 0px;
  }

  .inscriptionBadge {
    margin: 4vh 0px 4vh 0px;
  }

  .forgotPassword {
    margin: 4vh 0px 4vh 0px;
  }


}
