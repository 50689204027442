.moonPicStyle {
  position: absolute;
  left: 5px;
  bottom: 50px;
  height: 80px;
  z-index: 100;
  border-radius: 40px;
  opacity: 0.8;
}

.toolTipContentStyle {
  display: block;
  font-family: 'Arial', sans-serif;
}
