.atlasParentDiv {
  width: 100vw;
  height: 100vh;
}

.lightPollutionAtlasNavbar {
  opacity: 0.8;
  background-color: #028090;
  width: 100vw;
}

#lightPollutionAtlasHeading {
  color: #FFFFFF;
  font-size: 1.8vh;
  font-family: 'Arial', sans-serif;
  padding: 0px;
  margin: 0px;
}

.locateMe {
  color: #FFFFFF;
  transition: transform 0.3s;
}

.locateMe:hover {
  transform: translateY(-1px);
}

.mapLink {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin-right: 20px;
}

.homeLinkLightPollutionAtlas {
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin-right: 20px;
}

.istilAcknowledgement {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
}

.atlasParentDiv img[src*='googleusercontent.com']{
  opacity: .6;
}

.copyright {
  font-size: 2em;
  margin: 0px 0px 0px 0px;
  position: absolute;
  bottom: 8vh;
  left: 4vw;
  color: #FFFFFF;
}

.legend {
  font-size: 2em;
  margin: 0px 0px 0px 0px;
  position: absolute;
  bottom: 50vh;
  left: 4vw;
  color: #FFFFFF;
}

@media only screen and (min-height: 1020px) and (orientation: portrait) {
  #lightPollutionAtlasHeading {
    font-size: 2.4vh;
  }
}

@media only screen and (orientation: landscape) {
  #lightPollutionAtlasHeading {
    font-size: 3.2vh;
  }

}

/* all devices with width greater than 760 px. This creates space between */
/* map title and geolocation pin. */
@media only screen and (min-width: 760px) {
  .locateMe {
    margin-left: 40px;
  }
}
