.textAddLocation{
  height: 55vh;
  overflow: scroll;
}

.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}

.icon-wrapper .anticon {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 16px;
  color: rgba(0, 0, 0, .25);
}

.icon-wrapper .anticon:first-child {
  left: 0;
}

.icon-wrapper .anticon:last-child {
  right: 0;
}

.addLocationStyle{
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin: 0px 20px 0px 0px;
  padding: 0px;
}

.addLocationStyle:hover,
.addLocationStyle:focus{
  color: #59D2FE;
  text-decoration: none;
  border: none;
  outline: none;
}
