
.location-name {
  color: #FFFFFF;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  margin-top: 10px;
}

.textdesc {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.webSiteLabel {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.webSiteLink {
  margin-left: 5px;
  text-decoration: underline;
  color: #FFFFFF;
  overflow: auto;
}

.webSiteLink:hover {
  color: #FFFFFF;
  overflow: auto;
}


.uniqueURL {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  overflow: auto;
  border: solid;
  border-width: thin;
  padding: 1vh;
  border-radius: 1vh;
  margin: 0px 0px 1vh 0px;
  background-color: #3BA99C;
}

.cardStyle {
  background-color: #028090;
  text-align: center;
  color: #FFFFFF;
  height: 85vh;
  margin-top: 13vh;
}


.heading {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rootStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.geocoord {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  margin-bottom: 10px;
}

.latLong{
  margin: 0px 30px;
}

.weatherInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.weatherdesc {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
}

.paraStyle {
  text-align: justify;
  font-size: 2vh;
  font-family: 'Arial', sans-serif;
}

.footerStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.weatherTextStyle {
  text-align: left;
}

.thermoIcon {
  font-size: 2em;
  margin-right: 10px;
  margin-left: 4px;
}

.sunnyIcon {
  font-size: 2em;
  margin-right: 10px;
  margin-left: 4px;
}

.cityIcon {
  font-size: 3em;
  margin-left: 20px;
}

.windIcon {
  font-size: 2em;
  margin-right: 10px;
  margin-left: 20px;
}

.descriptionIconStyle {
  font-size: 2em;
  color: #FFFFFF;
}

.weatherIconStyle {
  color: #FFFFFF;
  font-size: 7em;
  margin-right: 35px;
}

.planetIcon {
  color: #FFFFFF;
  font-size: 3em;
  margin-left: 20px;
}

.moonIcon {
  color: #FFFFFF;
  font-size: 2em;
  margin-left: 20px;
}

.bortleStyle {
  border: solid;
  border-width: thin;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.smileyIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.descriptionCard {
  overflow-y: scroll;
}

.telescopeIconStyle {
  height: 60px;
  width: 60px;
}

.telBBStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonWeather{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

}

.buttonStyleSelec {
  color: #FFFFFF;
  border-color: #FFFFFF;
  font-family: 'Arial', sans-serif;
  margin: 10px;
  font-size: 2vh;
}

.buttonStyleSelec:hover {
  color: #028090;
  border-color: #FFFFFF;
  background-color: #FFFFFF;
}

.buttonDescStyleUns{
  color: #028090;
  background-color: #FFFFFF;
  border-color: #028090;
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
}

.buttonDescStyleUns:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
  background-color: transparent;
}

.widgets {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Arial', sans-serif;
  margin-bottom: 10px;
}

.widgetCar{
  font-size: 2em;
  margin: 0px 0px 0px 0px;
}

.widgetDirections{
  font-size: 2em;
  margin: 0px 0px 0px 0px;
}

.widgetWaze{
  font-size: 2em;
  margin: 0px 0px 0px 0px;
}

.gmapsLink{
  color: #FFFFFF;
  outline: none;
  border: none;
}

.gmapsLink:hover,
.gmapsLink:focus{
  color: #FFFFFF;
  border: none;
  outline: none;
}

.wazeLink{
  color: #FFFFFF;
  outline: none;
  border: none;
}

.wazeLink:hover,
.wazeLink:focus{
  color: #FFFFFF;
  border: none;
  outline: none;
}

.ratingElements{
  font-size: 2vh;
  display: inline;
}

.isochroneButton {
  border-radius: 0.6vh;
  margin: 0px 0px 10px 0px;
  background-color: transparent;
  font-family: 'Arial', sans-serif;
  color: #FFFFFF;
  border-color: #FFFFFF;
  font-size: 2vh;
}

.isochroneButton:hover,
.isochroneButton:focus{
  color: #FFFFFF;
  border-color: #FFFFFF;
  outline: none;
}

.parkingColumn {
  margin: 0px;
  padding: 0px;
}

.textDetails {
  font-family: 'Arial', sans-serif;
  font-size: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.comments-list,
.add-new-comment{
  width: 90%;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 760px){
  .comments-list,
  .add-new-comment{
    width: 100%;
  }
}

.comments-list,
.add-new-comment{
  margin-top: 10px;
}

.comments-list .container-comment{
  padding: 5px 0;
}

.comments-list .ant-comment-content{
  background-color: white;
  border-radius: 10px;
}

.comments-list .ant-comment-avatar{
  margin: 0;
  padding: 0;
}

.comments-list .ant-comment-content-author{
  background-color: #d5d5d5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: left;
  padding: 10px;
}

.comments-list .ant-comment-content-author-name{
  display: inline-flex;
}

.comments-list .ant-comment-content-author-name > *{
  font-size: 1.2em;
  font-weight: bold;
  color: black;
  margin: 0;
  line-height: 1;
}

.comments-list .ant-comment-content-author-time > *{
  color: #696969;
  margin: 0;
  line-height: 1;
}

.comments-list .ant-comment-inner{
  padding: 0;
}

.comments-list .ant-comment-content-detail{
  padding: 10px;
}

.comments-list .ant-comment-content-detail p{
  text-align: justify;
  color: black;
  font-size: .9em;
}


.comments-list .ant-comment-actions{
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px auto;
}
.comments-list .container-remove-comment{
  padding: 10px;
}

.not-comments-container{
  padding: 10px;
}

.not-comments-container .not-comments{
  font-size: 4em;
  color: rgba(255,255,255,0.3);
}

.add-comment-signin,
.not-comments-container p{
  color: rgba(255, 255, 255, 0.5);
}

.submit-comment{
  background-color: #028090;
  color: white;
  border: 1px solid white;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.24);
  transition: all ease .3s;
}

.btn-secondary.disabled.submit-comment,
.btn-secondary.disabled.submit-comment:hover,
.btn-secondary.disabled.submit-comment:active,
.btn-secondary.disabled.submit-comment:focus{
  color: #ffffff;
  background-color: #028090;
  border-color: #d0d0d0;
  box-shadow: none;
}

.btn-secondary.submit-comment:hover,
.btn-secondary.submit-comment:active,
.btn-secondary.submit-comment:focus{
  color: #028090;
  background-color: white;
  border-color: white;
}


/* This rule makes size of envelopping border of unique URL adjust to width */
/* of URL content inside for landscape orientation. */
@media only screen and (orientation: landscape) {
  .uniqueURL {
    font-family: 'Arial', sans-serif;
    font-size: 2vh;
    overflow: auto;
    border: solid;
    border-width: thin;
    padding: 1vh;
    border-radius: 1vh;
    margin: 0px 0px 1vh 0px;
    background-color: #3BA99C;
    display: inline-block;
  }

}
